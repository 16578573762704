<template>
  <div class="activeDetail titlesTop">
    <headTitle title="队伍详情" />
    <div class="activeLIstBox">
      <div class="active_info">
        <div class="active_title">
          <span>{{ teamName }}</span>
        </div>
        <div class="active_btn" v-show="siteShow" @click="enterChildPage(params.id, teamName)">下级部门></div>
      </div>
      <div class="jianjie" @click="GoIntroduce">简介</div>
      <div class="active_list">
        <div v-for="(item, index) in allData" :key="index" @click="teamChange(item)">
          <div>
            <span class="active_num">{{ item.count }}</span>
            <span class="active_detail">{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mid_active">
      <div class="left_active" @click="linkUrl('punchtheclock', '活动')">
        <p>参加活动</p>
        <p>已开展{{ teamCount.activity_count }}场活动</p>
      </div>
      <div class="right_active" @click="linkUrl('teamList', '团队建设')">
        <p>寻找团队</p>
        <p>已开展{{ teamCount.group_count }}个团队</p>
      </div>
    </div>
    <div class="briefIntroduction" v-show="haveTeam">
      <div class="intro_head">
        <span class="line_left"></span>
        <span class="circle"></span>
        <span v-if="teamName" class="intro_title">{{ teamName }}</span>
        <!-- <span v-else class="intro_title">{{ GlobalteamName }}</span> -->
        <span class="circle"></span>
        <span class="line_left"></span>
      </div>
      <div class="intro_body">
        <div v-for="(item, index) in centerRegions" :key="index">
          <div class="intro_list">
            <img src="../../assets/images/start.png" alt />
            <span class="intro_content">{{ item.regionName }}</span>
            <span class="intro_btn" @click="enterDetail(item)">查看详情</span>
          </div>
        </div>
      </div>
    </div>

    <div class="last_active" v-if="recentAcitivity.length">最新活动</div>
    <!-- <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp"> -->
    <div class="zhaomu_box" v-if="recentAcitivity.length">
      <div
        v-for="(item, index) in recentAcitivity"
        :key="index"
        class="perBoxContent"
        @click="goDetil(item)"
      >
        <div class="zhaomu_title">
          <div>{{ item.name }}</div>
          <!-- <div v-if="item.status === 5">停止招募</div>
          <div v-else>招募中</div>-->
        </div>
        <div class="zhaomu_position">{{ item.regionName }}</div>
        <div class="zhaomu_info">
          <span>活动时间:{{ item.activityBeginTime.substring(0, 10) }}</span>
          <span>招募人数:{{ item.recruitPeople }}人</span>
        </div>
      </div>
    </div>
    <div id="empty"></div>
    <!-- </mescroll-vue> -->
  </div>
</template>

<script>
import ArmyBuildapi from "@/api/armyBuild";
// import MescrollVue from "mescroll.js/mescroll.vue";

export default {
  data() {
    return {
      mescroll: null,
      mescrollDown: {},
      mescrollUp: {
        callback: this.upCallback,
        page: {
          num: 0,
          size: 10,
        },
        empty: {
          warpId: "empty",
          icon: "",
          tip: "暂无数据",
        },
      },
      params: {
        id: "", // 区域id
        page: 0,
        size: 0,
        memberId: "",
      },
      centerRegions: [],
      allData: [], // 当前区域志愿者人数、服务时长、活动场次等数据
      teamName: "", // 所站的名称
      teamCount: {}, //团队和活动数量
      childRegions: [], // 所下的所有站点
      siteShow: false, // 是否显示下级部门按钮
      haveTeam: false,//是否显示
      recentAcitivity: [], // 当前区域下的最新活动
    };
  },
  components: {
    // MescrollVue,
  },
  mounted() {
    this.params.appName = this.appName;
    this.params.id = this.$route.query.id;
    this.getChildRegions();
    this.getTeamCount();
    this.getArmyData();
    this.upCallback(); // 获取最新活动

  },
  methods: {
    teamChange(item) {//点击团队数量
      console.log(item)
      if (item.name == '团队数量' && item.count !== 0) {
        ArmyBuildapi.queryTeam({ appName: this.appName, regionId: this.$route.query.id }).then((res) => {
          console.log("团队数量", res);
          if (res.success) {
            if (res.data.length) {
              this.centerRegions = res.data;
              this.haveTeam = true
            }

          }
        })
      }
    },
    // 进入子区域详情页
    enterDetail(val) {
      console.log(val);
      // this.$route.query.id = val.id
      // this.params.id = val.id
      // this.getChildRegions()
      // this.getChildRegions();
      // this.getTeamCount();
      // this.getArmyData();
      // this.upCallback();
      // console.log("11");
      // let newUrl = this.$router.resolve({
      //   path: "/armyDetail",
      //   query: { id: val.id }
      // });
      // window.open('http://www.baidu.com', "_blank");
      var link = '';
      if (this.$route.query.sign) {
        link = window.location.href.split("#/")[0] + "#/army?id=" + val.id + "&flag=end";
      } else {
        link = window.location.href.split("#/")[0] + "#/army?id=" + val.id;
      }

      window.location.href = link
    },
    //进去介绍页面
    GoIntroduce() {
      var link = "Armyintroduce?id=" + this.params.id;
      window.location.href = '/#/' + link;
    },
    // 跳转进入志愿者招募详情中
    goDetil(val) {
      if (this.isToken()) { // 没用登入调到登入
        var link = "voluntaryRecruitmentDetail?id=" + val.id;
        window.location.href = '/#/' + link;
      }
    },
    //跳转链接
    linkUrl(url, title) {
      if (url === 'teamList') { //加入团队
        if (this.isToken()) { // 没有登入跳登入
          window.location.href = '/#/' + url;
        }
      } else { // 开展的活动
        window.location.href = '/#/' + url;
      }
    },
    // 获取当前区域志愿者人数、服务时长、活动场次等数据
    getArmyData() {
      let params = {
        appName: this.appName,
        id: this.params.id,
        memberId: this.params.memberId
      }
      ArmyBuildapi.getAreaAllData(params)
        .then((res) => {
          // console.log(res.data);
          this.allData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取当前区域的所有部门，如果有子部门则显示下级部门按钮
    getChildRegions() {
      let params = {
        appName: this.appName,
        regionId: this.params.id,
      }
      ArmyBuildapi.getPlaceById(params).then((res) => {
        // alert(JSON.stringify(res.data));
        if (res.data.length !== 0) {
          this.siteShow = true;
          this.haveTeam = true
          this.centerRegions = res.data;
        } else {
          this.haveTeam = false
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    // 获取当前区域下的团队数和开展活动数
    getTeamCount() {
      ArmyBuildapi.getCountOfTeam(this.params)
        .then((res) => {
          console.log('teamName', res);
          this.teamName = res.data.name;
          this.teamCount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查看当前区域的下级区域
    enterChildPage(id, name) {
      // console.log(id);
      var link =
        window.location.href.split("#/")[0] +
        "#/army?id=" +
        id +
        "&sign=suoTozhan";
      window.location.href = link;
    },
    // 查看当前区域下的最新活动
    upCallback() {
      this.params.page = 1;
      this.params.size = 10;
      ArmyBuildapi.getLastActivity(this.params)
        .then((res) => {
          // console.log(res.data);
          if (res.data.items) {
            let arr = res.data.items;
            this.recentAcitivity = arr;
          }
        })
        .catch((err) => {
          console.log(err)
        });
    },
  },
};
</script>

<style scoped lang="less">
.activeDetail {
  position: relative;
  .activeLIstBox {
    // &:nth-child(1) {
    background: url("../../assets/images/bg_one.png");
    background-size: 100% 100%;
    color: #fff;
    // }
  }
}
.activeLIstBox {
  font-size: 0.28rem;
  width: 94%;
  height: auto;
  border-radius: 5px;
  background: #fff;
  margin: 0.2rem auto;
  padding: 0.2rem;
  box-sizing: border-box;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  // background: url();
  // margin-top: -19%;
  .active_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .active_title {
      display: flex;
      align-items: center;
      font-size: 5vw;
      font-weight: bold;
      // .active_img {
      //     width: 9vw;
      // }
    }
    .active_btn {
      padding-right: 3vw;
      opacity: 0.8;
    }
  }
  .jianjie {
    width: 20vw;
    background: #52b0f3;
    height: 6vw;
    line-height: 6vw;
    border-radius: 25px;
    text-align: center;
    margin: 3vw 0;
    opacity: 0.8;
  }

  .active_list {
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    div {
      display: flex;
      flex-flow: column;
      align-items: center;
    }
    .active_num {
      font-size: 5vw;
      font-weight: bolder;
    }
    .active_detail {
      font-size: 3.5vw;
      opacity: 0.8;
    }
  }
}
.briefIntroduction {
  font-size: 0.28rem;
  width: 94%;
  height: auto;
  border-radius: 5px;
  background: #fff;
  margin: 0.2rem auto;
  padding: 0.2rem;
  box-sizing: border-box;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  margin-top: 30px;
  .intro_head {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    .intro_title {
      font-size: 5vw;
      font-weight: bold;
      padding: 0 5px;
    }
    .line_left {
      display: inline-block;
      border: 1px #cd1218 solid;
      width: 10vw;
    }
    .circle {
      width: 5px;
      height: 5px;
      background-color: #cd1218;
      border-radius: 50%;
      display: inline-block;
    }
  }
  .intro_body {
    display: flex;
    flex-wrap: wrap;
    .intro_list {
      margin-left: 4px;
      border-radius: 5px;
      flex: 0 0 33%;
      height: 35vw;
      background: #e7f4fd;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 2vw;
      .intro_content {
        width: 28vw;
        text-align: center;
        margin: 2vw 0;
        font-weight: bold;
      }
      .intro_btn {
        width: 16vw;
        background: #1377e2;
        color: #fff;
        padding: 1px 5px;
        border-radius: 7px;
      }
      img {
        width: 10vw;
        border-radius: 50%;
        border: #fff 5px solid;
      }
    }
  }
}
.mid_active {
  display: flex;
  justify-content: space-between;
  padding: 0 3vw;
  color: #fff;
  .left_active {
    padding: 5vw;
    background: url("../../assets/images/bg_two.png");
    background-size: 100% 100%;
    width: 37%;
  }
  .right_active {
    padding: 5vw;
    background: url("../../assets/images/bg_three.png");
    background-size: 100% 100%;
    width: 37%;
  }
  div {
    p {
      &:nth-child(1) {
        font-size: 5vw;
        font-weight: bold;
        margin-bottom: 1vw;
      }
      &:nth-child(2) {
        opacity: 0.8;
      }
    }
  }
}
.last_active {
  padding: 3vw 0 2vw 3vw;
  font-size: 4vw;
  font-weight: bolder;
}
.zhaomu_box {
  padding: 4vw;
  background: #fff;
  margin: 0 3vw;
  .perBoxContent {
    margin-bottom: 4vh;
  }
  .zhaomu_title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    div {
      &:nth-child(1) {
        width: 68vw;
        font-size: 3.9vw;
      }
      &:nth-child(2) {
        width: 16vw;
        background: #00a0e9;
        border-radius: 4px;
        text-align: center;
        height: 6vw;
        line-height: 6vw;
        color: #fff;
      }
      &:nth-child(3) {
        width: 16vw;
        background: #696969;
        border-radius: 4px;
        text-align: center;
        height: 6vw;
        line-height: 6vw;
        color: #fff;
      }
    }
  }
  .zhaomu_position {
    opacity: 0.8;
    padding: 2vw 0;
    margin-bottom: 0.5vw;
    color: #828282;
    border-bottom: 1px solid #f5f5f5;
  }
  .zhaomu_info {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 0.28rem;
      &:nth-child(1) {
        color: #c01920;
      }
      &:nth-child(2) {
        color: #828282;
      }
    }
  }
}
</style>